
import CompanyRelations from "~/graphql/Person/CompanyRelations.gql";
import CompletePortfolio from "~/graphql/Person/CompletePortfolio.gql";

import { getPropertyIcon as _getPropertyIcon } from "~/helpers/property-helpers.js";
import { getPropertyAddress } from "~/helpers/property-helpers.js";
import { getAddressAsLongString } from "~/helpers/address-helpers.js";
import {
  PROPERTY_TYPE_MAIN_PROPERTY,
  PROPERTY_TYPE_CONDO,
  PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT,
  PROPERTY_TYPE_UNKNOWN,
  getPropertyType,
} from "~/helpers/property-helpers.js";
import AddToListDialog from "~/components/list/AddToListDialog.vue";

export default {
  components: {
    AddToListDialog,
  },

  inheritAttrs: false,

  data: function () {
    return {
      showAddToList: false,
      propertiesToSave: [],
    };
  },

  apollo: {
    person: {
      query: CompanyRelations,

      update(data) {
        return data.person;
      },

      error(error) {
        console.error("Person failed:", error);
      },

      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
    portfolio: {
      query: CompletePortfolio,
      update(data) {
        return data.completePortfolio;
      },
      error(error) {
        console.error("Error fetching portfolio:", error);
      },
      variables() {
        const cvrNumbers = [];
        if (this.person && this.person.relations) {
          this.person.relations
              .filter((relation) => relation.roles.includes("REAL_OWNER"))
              .forEach((relation) => cvrNumbers.push(parseInt(relation.cvrNumber)));
        }
        return {
          id: this.$route.params.id,
          cvrNumbers: cvrNumbers,
        };
      },
    },
  },

  computed: {
    loading: function () {
      return this.$apollo.queries.person.loading || this.$apollo.queries.portfolio.loading;
    },

    properties: function () {
      if (this.portfolio) {
        return this.portfolio;
      }
      return {};
    },
  },

  methods: {
    getAddress(property) {
      const address = getPropertyAddress(property);

      return getAddressAsLongString(address) ?? this.$t("COMPANY_LABEL_UNKNOWN_ADDRESS");
    },

    hasPurchaseSum(property) {
      return (
        property.ownerChanges != null &&
        property.ownerChanges.length > 0 &&
        property.ownerChanges.some((ownerChange) => ownerChange.transactionInfo != null && ownerChange.transactionInfo.totalPurchaseSum != null)
      );
    },

    getLatestPurchase(property) {
      if (property.ownerChanges == null || property.ownerChanges.length == 0) {
        return {
          date: null,
          price: null,
        };
      }

      let ownerChangesWithPriceSorted = [...property.ownerChanges]
        .filter((oc) => oc.transactionInfo != null && oc.transactionInfo.totalPurchaseSum != null)
        .sort((a, b) => new Date(b.handoverDate).getTime() - new Date(a.handoverDate).getTime());

      if (ownerChangesWithPriceSorted.length == 0) {
        return {
          date: null,
          price: null,
        };
      }

      let latestOwnerChangeWithPrice = ownerChangesWithPriceSorted[0];

      return {
        date: latestOwnerChangeWithPrice.handoverDate,
        price: latestOwnerChangeWithPrice.transactionInfo.totalPurchaseSum,
      };
    },

    getLatestPropertyValuation(property) {
      if (!property.valuations || property.valuations.lengths == 0) {
        return 0;
      }

      let properyValuationsSorted = [...property.valuations].sort((a, b) => b.year - a.year);

      return properyValuationsSorted[0]?.propertyValuation;
    },

    hasLink(property) {
      return property.bfeNumber != null;
    },

    navigateToProperty(property) {
      const search = {
        id: property.bfeNumber,
        searchTerm: this.getAddress(property),
      };

      this.$store.dispatch("search/initiateAddressSearch", {
        search,
        track: "person portfolio",
      });
    },

    savePropertyToList(property) {
      this.propertiesToSave = [property.bfeNumber];

      this.showAddToList = true;
    },

    saveAllPropertiesToList() {
      this.propertiesToSave = this.properties;

      this.showAddToList = true;
    },

    isPropertyInList(property) {
      return property.isPropertyOnList;
    },

    getPropertyIcon(property) {
      return _getPropertyIcon(property);
    },

    getPropertyTypeString(property) {
      switch (getPropertyType(property)) {
        case PROPERTY_TYPE_MAIN_PROPERTY:
          return this.$t("COMPANY_LABEL_TYPE_MAIN_PROPERTY");
        case PROPERTY_TYPE_CONDO:
          return this.$t("COMPANY_LABEL_TYPE_CONDO");
        case PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT:
          return this.$t("COMPANY_LABEL_TYPE_BUILDING_ON_FOREIGN_PLOT");
        case PROPERTY_TYPE_UNKNOWN:
          return this.$t("COMPANY_LABEL_TYPE_UNKNOWN");
        default:
          return this.$t("COMPANY_LABEL_TYPE_UNKNOWN");
      }
    },
  },
};
